import { Button, HTMLTable, InputGroup, Spinner } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { DocItem, getDocsByKeyword } from '../client';
import { useLocation, useSearch } from 'wouter';

import './common.css';
import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

export default function DocsView() {
  const [keyword, setKeyword] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState<DocItem[]>([]);
  const setLocation = useLocation()[1];
  const searchString = useSearch();

  const input = new URLSearchParams(searchString).get('keyword') ?? '';

  async function search(input: string) {
    setLoading(true);
    try {
      setDocs(await getDocsByKeyword(input));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function redirectToSearch(input: string) {
    setLocation(`/docs?keyword=${encodeURIComponent(keyword)}`);
  }

  useEffect(() => {
    search(input);
  }, [input]);

  return (
    <main>
      <h1>Docs</h1>
      <section>
        <InputGroup
          placeholder="Search by company ticker"
          onChange={(e) => setKeyword(e.target.value)}
          rightElement={
            <Button icon="search" onClick={() => redirectToSearch(keyword)}>
              Search
            </Button>
          }
          onKeyDownCapture={(e) => e.key === 'Enter' && redirectToSearch(keyword)}
          style={{ marginBottom: 8 }}
          value={keyword}
        />
        {loading ? (
          <Spinner />
        ) : (
          <HTMLTable compact bordered width="100%">
            <tbody>
              {docs.length > 0 ? (
                docs.map((doc, i) => render(doc, String(i)))
              ) : (
                <tr>
                  <td colSpan={5}>No documents matched.</td>
                </tr>
              )}
            </tbody>
          </HTMLTable>
        )}
      </section>
    </main>
  );
}

function render(doc: DocItem, index: string) {
  return (
    <tr key={index}>
      <td>{doc.ticker}</td>
      <td>{doc.form_type}</td>
      <td>{doc.filed_at.slice(0, 10)}</td>
      <td>
        <a href={`/docs/${doc.id}`}>{doc.accession_no}</a>
      </td>
      <td>{doc.match_explain}</td>
    </tr>
  );
}
