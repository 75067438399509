import DocDetailView from './DocDetailView';
import DocsView from './DocsView';
import { Route } from 'wouter';

import './common.css';

export default function App() {
  return (
    <>
      <Route path="/">{<DocsView />}</Route>
      <Route path="/docs/">{<DocsView />}</Route>
      <Route path="/docs/:docId">{<DocDetailView />}</Route>
    </>
  );
}
