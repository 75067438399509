import axios, { AxiosRequestConfig } from 'axios';

const API_HOST = 'https://demo-service-3crzxaauca-uc.a.run.app';
const AXIOS_CONFIG = { baseURL: API_HOST, headers: { 'Content-Type': 'application/json' } } as AxiosRequestConfig;

export interface DocItem {
  accession_no: string;
  filed_at: string;
  form_type: string;
  id: string;
  match_explain: string;
  ticker: string;
}

export interface Doc {
  accession_no: string;
  cik: string;
  company_name: string;
  data: DocData;
  form_type: string;
  filed_at: string;
  id: string;
  ticker: string;
  url: string;
}

interface DocData {
  mentions: Mention[];
  items: Item[];
}

interface Item {
  title: string;
  code: string;
  content: string;
}

interface Mention {
  name: string;
  keywords: string;
  relationship: string;
  description: string;
}

interface GetDocsResponse {
  error?: string;
  items: DocItem[];
}

export async function getDocsByKeyword(keyword: string): Promise<DocItem[]> {
  const res = await axios.get(`/v1/docs?keyword=${keyword}`, AXIOS_CONFIG);
  const { error, items }: GetDocsResponse = await res.data;
  if (error) {
    throw error;
  }
  return items;
}

interface GetDocResponse extends Doc {
  error?: string;
}

export async function getDocById(id: string): Promise<Doc> {
  const res = await axios.get(`/v1/docs/${id}`, AXIOS_CONFIG);
  const body: GetDocResponse = await res.data;
  if (body.error) {
    throw body.error;
  }
  return body;
}
