import { Spinner } from '@blueprintjs/core';
import { Doc, getDocById } from '../client';
import { useEffect, useState } from 'react';
import { useParams } from 'wouter';
import Markdown from 'react-markdown';

import './common.css';

interface Params {
  docId: string;
}

export default function DocDetailView() {
  const [loading, setLoading] = useState<boolean>(true);
  const [doc, setDoc] = useState<Doc>({
    accession_no: '',
    cik: '',
    company_name: '',
    data: { mentions: [], items: [] },
    form_type: '',
    filed_at: '',
    id: '',
    ticker: '',
    url: '',
  });

  const { docId } = useParams<Params>();

  useEffect(() => {
    setLoading(true);
    getDocById(docId)
      .then((doc) => {
        setDoc(doc);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        setLoading(false);
      });
  }, [docId]);

  let relevantDetailsText = <></>;
  if (doc) {
    relevantDetailsText = (
      <>
        {doc.data.mentions.map((m, index) => (
          <p key={index}>
            <span style={{ fontWeight: 'bold' }}>{m.name}</span> <br />
            {m.relationship}
            <br />
            <span style={{ color: '#888888', fontSize: 13 }}>
              {m.keywords && `${m.keywords} - `} {m.description}
            </span>
          </p>
        ))}
      </>
    );
  }

  const urlParts = doc?.url.split('/') ?? [''];
  const filename = urlParts[urlParts.length - 1];

  return (
    <main>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <h1>{doc.ticker}</h1>
          <section>
            Company: {doc.company_name}
            <br />
            Form Type: {doc?.form_type}
            <br />
            Filed at: {doc?.filed_at}
            <br />
            Accession Number: {doc?.accession_no}
            <br />
            CIK: {doc?.cik}
            <br />
            Original HTML version: <a href={doc?.url}>{filename}</a>
          </section>
          <section>
            <h2>Relevant details</h2>
            {relevantDetailsText}
          </section>
          <section>
            <h2>Summary</h2>
            {doc.data.items.map((item, index) => (
              <Markdown key={index}>{item.content}</Markdown>
            ))}
          </section>
        </>
      )}
    </main>
  );
}
